<template>
	<div class="integral-list el-content">
		<router-link to="/adhibition/integral/edit">
			<a-button v-has="{action:'/adhibition/integral/edit',plat:0}" type="primary">
				<i class="ri-add-line"></i>添加商品
			</a-button>
		</router-link>
		<a-table :pagination="false" row-key="goods_id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'goods_id'},
			{title:'商品信息',dataIndex:'goods',slots:{customRender:'goods'}},
			{title:'价格信息',dataIndex:'price',slots:{customRender:'price'}},
			{title:'库存/销量',dataIndex:'count',slots:{customRender:'count'}},
			{title:'状态',dataIndex:'status',slots:{customRender:'status'}},
			{title:'排序',dataIndex:'rank'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]" >
			<template #goods="{record}">
				<div style="display: flex;align-items: center;">
					<a-image :src="record.images[0]" style="width: 80px;height: 80px;margin-right: 5px;"/>
					<div style="width: 80%;">
						<div>{{record.name}}</div>
						<div><span class="f12">重量:</span>{{record.weight}}</div>
						<div>
							<a-tag color="#FF0066" v-if="record.type== 1">实物商品</a-tag>
							<a-tag color="#FF0066" v-if="record.type== 2">优惠券</a-tag>
							<a-tag color="#FF0066" v-if="record.type== 3">购物卡</a-tag>
							<a-tag color="#FF0066" v-if="record.type== 4">兑换余额</a-tag>
						</div>
					</div>
				</div>
			</template>
			<template #price="{record}">
				<div><span class="f12">原价：</span><span style="color: #E74C3C;">{{record.amount_org}}</span>元</div>
				<div><span class="f12">所需支付积分：</span><span style="color: #E74C3C;">{{record.integral}}</span></div>
				<div><span class="f12">所需支付金额：</span><span style="color: #E74C3C;">{{record.amount_dis}}</span>元</div>
			</template>
			<template #count="{record}">
				<div><span class="f12">库存：</span>
				{{record.number}}
				<span v-if="record.number == 0" style="color: #E74C3C;">（库存不足）</span>
				<span v-else-if="record.number <= 20" style="color: #E74C3C;">（库存紧张）</span>
				</div>
				<div><span class="f12">真实销量：</span>{{record.sale_num}}</div>
				<div><span class="f12">虚拟销量：</span>{{record.sale_vr_num}}</div>
			</template>
			<template #status="{record}">
				<a-tag color="#00CC66" v-if="record.status == 1">已上架</a-tag>
				<a-tag color="#999" v-if="record.status == 0">已下架</a-tag>
			</template>
			<template #action="{record}">
				<a-space>
					<router-link :to="{path:'/adhibition/integral/edit',query:{goods_id:record.goods_id}}">
						<kd-button type="primary" title="编辑" icon="ri-edit-line" v-has="{action:'/adhibition/integral/edit',plat:0}"></kd-button>
					</router-link>
					<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_adhibiton_integral_goods_del',plat:0}"  @click="delIntegralGoods(record.goods_id)"></kd-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getIntegralData(info.page,e)}"
				@change="(e)=>{getIntegralData(e,info.limit)}"
			/>
		</div>
	</div>
</template>

<script>
import { onActivated, reactive, toRefs } from 'vue'
import integralModel from '@/api/addons/integral'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'

export default{
	name:"IntegralGoodsList",
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:10
			}
		})
		onActivated(()=>getIntegralData(state.info.page,state.info.limit))
		getIntegralData(1,state.info.limit)

		function getIntegralData(page,limit){
			integralModel.getIntegral(page,limit,null,res=>state.info = {limit,...res})
		}
		const delIntegralGoods =id=>integralModel.deleteIntegral(id,()=>getIntegralData(state.info.page,state.info.limit))

		onBeforeRouteLeave((to,from,next)=>{
			let type = to.path == '/adhibition/integral/edit' ? 1 : 2
			store.dispatch("setKeepPage",{name:from.name,type})
			next();
		})

		return{
			...toRefs(state),
			getIntegralData,
			delIntegralGoods
		}
	}
}
</script>

<style lang="scss">
	
</style>
