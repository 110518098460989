import { $post } from '@/api/http.js'
import tool from '@/util/tool.js'
import common from "@/api/common.js"
import router from '@/router'

class Integral{

    /**
     * 获取积分商品分类信息
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getIntType(page,limit,fn){
        let param = { page,limit } 
        common.getDataList("getIntCategoryList",param).then(res=>fn(res))
    }

    /**
     * 新增或编辑积分分类
     * @param {object} param 
     * @param {function} fn 
     */
    addOrEditIntType(param,fn){
        if( !tool.returnMessage(param.name,'请填写分类名称')) return
        if( !param.id ) delete param.id
        $post("setIntCategory",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 删除积分商品分类
     * @param {number} id 
     * @param {function} fn 
     */
    deleteIntType(id,fn){
        tool.confirm("确认删除订单信息?").then(()=>{
            $post("delIntCategory",{id}).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 获取积分商品信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getIntegral(page,limit,extra,fn){
        let param = { page ,limit }
        common.getDataList("getIntGoodsList",param).then(res=>fn(res))
    }

    /**
     * 获取积分商品详情
     * @param {number} id 
     * @param {function} fn 
     */
    getIntegralDetail(id,fn){
        $post('getIntGoods',{goods_id:id}).then(res=>fn(res.data.model))
    }

    /**
     * 删除积分商品
     * @param {number} id 
     * @param {function} fn 
     */
    deleteIntegral(id,fn){
        tool.confirm("确认删除商品信息吗?").then(()=>{
            $post("delIntGoods",{goods_id:id}).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 添加积分商品
     * @param {object} param 
     * @param {object} detail 
     */
    addOrEditIntegral(param,detail){
        if( !param.goods_id) delete param.goods_id
        $post("setIntGoods",{goods:param,detail}).then(()=>{
            tool.message("保存成功")
            router.go(-1)
        })
    }

    /**
     * 获取积分商品订单
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getIntOrder(page,limit,extra,fn){
        let param = { page,limit}
        if( extra && extra.key ) param.key = extra.key
        common.getDataList("getIntegralOrderList",param).then(res=>fn(res))
    }

    /**
     * 积分商城订单发货
     * @param {object} param 
     * @param {function} fn 
     */
    intOrderSend(param,fn){
        let data = {
            id:param.id,
            express_company:param.express_company,
            express_no:param.express_no
        }
        $post('ShipIntegramOrder',data).then(res=>{
            tool.message('发货成功')
            fn(res.data)
        })
    }

    /**
     * 积分商城确认收货
     * @param {number} id 
     * @param {function} fn 
     */
    confirmIntOrder(id,fn){
        tool.confirm("确认将订单状态设置为已收货吗？").then(()=>{
            $post('commitIntegralOrder',{id}).then(res=>{
                tool.message('操作成功')
                fn(res.data)
            })
        }).catch(()=>{})
    }
}

const integralModel = new Integral()
export default integralModel